import React, { useState } from 'react';
import Layout from '../components/layouts-and-providers/layout';
import Footer from '../components/global/footer';
import Image from 'next/image';
import { unstable_getServerSession } from 'next-auth';
import { GetServerSideProps } from 'next';
import { authOptions } from './api/auth/[...nextauth]';
import { BsCheckLg } from 'react-icons/bs';
import List from '../components/global/list';
import { BiChevronRight } from 'react-icons/bi';
import { useRouter } from 'next/router';
import LegalAgreement from '../components/global/legal-agreement';

const prompts = [
	// https://res.cloudinary.com/de8sb8oyd/image/upload/v1674957052/bewenz87ukiy6sqlthwk.png
	{ id: 100, label: `A photo of a dog wearing glasses` },

	// https://res.cloudinary.com/de8sb8oyd/image/upload/v1673667112/zmhwmtkpt7yyhjz0mp8z.png
	{ id: 200, label: 'A giraffe on rollerskates' },
];

const products = [
	{ id: 1, label: 'T-Shirt' },
	{ id: 2, label: 'Mug' },
	{ id: 3, label: 'Poster' },
];

const mockMap = new Map<number, string>([
	[
		101,
		'https://res.cloudinary.com/de8sb8oyd/image/upload/v1675556062/site_assets/mock_1_1_gs8huv.png',
	],
	[
		102,
		'https://res.cloudinary.com/de8sb8oyd/image/upload/v1675556020/site_assets/mock_1_2_youa4g.png',
	],
	[
		103,
		'https://res.cloudinary.com/de8sb8oyd/image/upload/v1675556131/site_assets/mock_1_3_qncez0.png',
	],
	[
		201,
		'https://res.cloudinary.com/de8sb8oyd/image/upload/v1675556067/site_assets/mock_2_1_qcnxhs.png',
	],
	[
		202,
		'https://res.cloudinary.com/de8sb8oyd/image/upload/v1675555988/site_assets/mock_2_2_rfccca.png',
	],
	[
		203,
		'https://res.cloudinary.com/de8sb8oyd/image/upload/v1675556130/site_assets/mock_2_3_clcyco.png',
	],
]);

const Login = () => {
	const router = useRouter();
	const [selectedPrompt, setSelectedPrompt] = useState(prompts[0]);
	const [selectedProduct, setSelectedProduct] = useState(products[0]);

	const renderMobileDescription = (): JSX.Element => {
		return (
			<>
				{/* Header */}
				<div className="text-center">
					<p className="text-2xl pt-2 font-semibold">
						Bring your imagination to life with{' '}
						<span className="text-3xl text-blue-700">AI</span>.
					</p>
					<div className="mt-4 mx-4 text-left">
						<p className="text-gray-700">
							<b className="text-gray-900">Image Fountain</b> is
							your one-stop shop to{' '}
							<b className="text-gray-900">
								dream up any image, painting, or sketch
							</b>
							.
						</p>
						<p className="mt-2 text-gray-700">
							When you&apos;re happy with your art,{' '}
							<b className="text-gray-900">hold it</b>,{' '}
							<b className="text-gray-900">hang it</b>, or even{' '}
							<b className="text-gray-900">wear it</b> with custom
							mugs, posters, t-shirts, and more!
						</p>
					</div>
				</div>
			</>
		);
	};

	const renderNonMobileDescription = (): JSX.Element => {
		return (
			<div className="ml-10">
				<div className="w-full flex justify-between items-center bg-red-0">
					<p className="pl-4 text-3xl font-semibold pt-4">
						Bring your imagination to life with{' '}
						<span className="text-4xl text-blue-700">AI</span>.
					</p>
				</div>

				<ul className="mb-6 space-y-3  text-left text-gray-500 dark:text-gray-400">
					<div className="flex items-center">
						<BsCheckLg className="mr-2" size={22} color="#20e62a" />
						<p className="text-xl ">
							<b className="font-bold text-gray-900">Describe</b>{' '}
							anything you want, in any style you want
						</p>
					</div>

					<div className="flex items-center">
						<BsCheckLg className="mr-2" size={22} color="#20e62a" />
						<p className="text-xl ">
							<b className="font-bold text-gray-900">Create</b>{' '}
							awesome images, paintings, and sketches
						</p>
					</div>

					<div className="flex items-center">
						<BsCheckLg className="mr-2" size={22} color="#20e62a" />
						<p className="text-xl ">
							<b className="font-bold text-gray-900">
								Hold, hang, or wear
							</b>{' '}
							your art with custom mugs, posters, t-shirts, and
							much more!
						</p>
					</div>
				</ul>
			</div>
			// </div>
		);
	};

	// this auto-adjusts to diff screen sizes (with columns flex vs row flex etc)
	const renderExploreSection = (): JSX.Element => {
		return (
			<div className="flex flex-col sm:flex-row lg:px-16 bg-red-00">
				{/* About section & drop down options */}
				<div className="flex flex-col items-center sm:w-4/5 sm:bg-slate-200 rounded-md rounded-t-none sm:rounded-t-md sm:p-2">
					{/* THIS IS FOR MOBILE */}
					<p className="sm:hidden text-center mt-2 text-gray-800 text-lg font-bold">
						Image Fountain has two simple steps:
					</p>

					{/* top level description on large screen */}
					<div className="hidden sm:flex flex-col w-full">
						<div>
							<h3 className="flex mx-11">
								Start exploring and create something special
							</h3>

							{/* THIS IS FOR LARGE */}
							<p className="flex mx-12 text-gray-600 font-semibold">
								Image Fountain leverages cutting-edge AI to
								empower you to create truly one of a kind art.
								Sign up is a single click and you have unlimited
								generations - only pay when you want us to ship
								you something.
							</p>
						</div>

						{/* <p className='mt-10 mx-12 mb-3'>Mix & match some of the examples!</p> */}
						<h4 className="mt-3	text-center">
							Create real-world art with Image Fountain&apos;s two
							simple steps:
						</h4>
					</div>

					<div className="bg-red-00 flex w-full flex-col">
						{/*  one line item and a dropdown */}
						<div className="flex flex-col items-center my-2">
							<p className="sm:text-xl font-semibold mb-1 sm:mb-3">
								<b>1.</b> Type in anything you can imagine:
							</p>
							<List
								className="ring-4 rounded"
								selected={selectedPrompt}
								options={prompts}
								onChange={setSelectedPrompt}
							/>
						</div>

						<div className="flex flex-col bg-gree00 items-center my-2 mb-2">
							<p className="sm:text-xl font-semibold mb-1 sm:mb-3">
								<b>2.</b> Simply choose a product and we&apos;ll
								ship it to you:
							</p>
							<List
								className="ring-4 rounded mb-2"
								selected={selectedProduct}
								options={products}
								onChange={setSelectedProduct}
							/>
						</div>
					</div>

					<div className="hidden sm:flex flex-col items-center justify-center w-full h-full">
						<button
							type="button"
							className="bg-gradient-to-r from-blue-500 to-indigo-700 rounded-md flex items-center px-6 py-4 m-4"
							onClick={() => router.push('/login-confirm')}
						>
							<div className="flex justify-center items-center">
								{/* <p className='mr-1 text-slate-700 font-semibold'>Login with</p> */}
								<p className="mr-1 text-white text-2xl font-semibold">
									Get Started
								</p>
								<BiChevronRight size={35} color="white" />
							</div>
						</button>
						<LegalAgreement />
					</div>
				</div>

				<div className="flex bg-blue-00 items-center w-full justify-center sm:justify-betwen ">
					<Image
						className="bg-red-00 w-52 h-52 sm:w-80 sm:h-80 md:w-96 md:h-96 lg:h-112 lg:w-112"
						src={
							mockMap.get(
								selectedPrompt.id + selectedProduct.id,
							) as string
						}
						width="500"
						height="500"
						alt={`an image of ${selectedPrompt.label} on a ${selectedProduct.label}`}
					/>
				</div>
			</div>
		);
	};

	return (
		// TODO: consider removing the nav bar here or passing in a field to remove the hamburger
		<Layout title="Login" isLoginPage={true} enableWaves={true}>
			{/* Mobile */}
			<div className="sm:hidden">
				<div className="bg-brand-slate-light rounded-md mt-4 mx-3">
					{renderMobileDescription()}
				</div>

				<div className="bg-brand-slate-light rounded-md mt-4 mx-3">
					{renderExploreSection()}
				</div>
				{/* mobile get started at the bottom */}
				<div className="flex flex-col sm:hidden items-center justify-center w-full h-full">
					<button
						type="button"
						className="ring ring-white bg-gradient-to-r from-blue-500 to-indigo-700 rounded-md flex items-center px-6 py-4 m-4"
						onClick={() => router.push('/login-confirm')}
					>
						<div className="flex justify-center items-center">
							<p className="mr-1 text-white text-2xl font-semibold">
								Get Started
							</p>
							<BiChevronRight size={35} color="white" />
						</div>
					</button>
					<LegalAgreement linkColor="white" />
				</div>
			</div>

			{/* Desktop */}
			<div className="hidden sm:flex h-full items-center justify-center w-full">
				<div className="flex-col bg-brand-slate-light rounded-md lg:w-10/12 pb-5">
					{renderNonMobileDescription()}
					{renderExploreSection()}
				</div>
			</div>
			<div className="mt-auto ">
				<Footer />
			</div>
		</Layout>
	);
};

export const getServerSideProps: GetServerSideProps = async (context) => {
	const session = await unstable_getServerSession(
		context.req,
		context.res,
		authOptions,
	);
	if (session) {
		return {
			redirect: {
				destination: '/',
				permanent: false,
			},
			props: { session },
		};
	}
	return {
		props: {},
	};
};

export default Login;
