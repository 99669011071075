const Footer = (): JSX.Element => {
	return (
		<>
			<footer className="site-footer hidden sm:flex p-1 w-full items-center justify-center bg-black/[0.5]">
				<p className="font-light italic text-white">
					Powered by two latent surfers
				</p>
			</footer>
		</>
	);
};
export default Footer;
