import Link from 'next/link';

type LegalAgreementProps = {
	linkColor?: string;
};

const LegalAgreement = ({ linkColor = 'sky-500' }: LegalAgreementProps) => {
	return (
		<div className="text-xs">
			By signing in you agree to our{' '}
			<Link href="/legal" target="_blank" className={`text-${linkColor}`}>
				terms and conditions
			</Link>
			.
		</div>
	);
};

export default LegalAgreement;
